import React, { ReactNode } from "react"
import {
  Container,
  createMuiTheme,
  createStyles,
  CssBaseline,
  Theme,
  ThemeProvider,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

interface LayoutProps {
  children: ReactNode | ReactNode[]
}

const darkTheme = createMuiTheme({
  palette: {
    type: "dark",
  },
})

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4),
    },
  })
)

export default function Layout({ children }: LayoutProps) {
  const classes = useStyles()

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Container maxWidth="lg" className={classes.container}>
        {children}
      </Container>
    </ThemeProvider>
  )
}
